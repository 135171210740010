import './scss/style.scss';
import 'bootstrap';

import { WOW } from 'wowjs';

/*Se inicializa el plugin para las animaciones*/
var wow = new WOW({
    live: false,
    scrollContainer: null
});

/*Todos los "load" cargan los bloques recurrentes*/
$('header').load("header.html");
$('footer').load("footer.html", function() {
    wow.init();
});
$('#block_formulario').load("block_formulario.html", function() {
    wow.init();
});
$('#block_cta').load("block_cta.html", function() {
    wow.init();
});

/*Cambio de estilos en el header*/
$(window).on("scroll", function(e) {

    if (parseInt(Math.round($(window).scrollTop())) > 50) {
        $('header').addClass('scroll-active');
    } else {
        $('header').removeClass('scroll-active');
    }
});

/*Se agregan las animaciones para toda la pagina que no cargan de menera recurrente*/
wow.init();